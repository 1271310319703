import React from 'react';
import Chip from '@material-ui/core/Chip';
import { Add, Block, Done } from '@material-ui/icons';

const iconSwitch = status => {
    switch (status) {
        case 'u':
            return <Add />;
        case 'a':
            return <Done />;
        case 's':
            return <Block />;
        default:
            return '';
    }
};

const labelType = {
    u: 'Nowe',
    a: 'Aktywne',
    s: 'Zablokowane',
};

export const AccountStatusChip = ({ status, kyc }) => (
    <Chip
        icon={iconSwitch(status)}
        label={labelType[status] + (kyc ? ` (${kyc})` : '')}
        color={status === 's' ? 'primary' : 'default'}
    />
);
