import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    //useTheme,
    makeStyles,
} from '@material-ui/core';
import theme from '../theme/index.js';
import ShowChartIcon from '@material-ui/icons/ShowChart';

const useStyles = makeStyles(() => ({
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
}));

const generateChartData = stats => {
    const data = {
        datasets: [],
        labels: [
            'Styczeń',
            'Luty',
            'Marzec',
            'Kwiecień',
            'Maj',
            'Czerwiec',
            'Lipiec',
            'Sierpień',
            'Wrzesień',
            'Październik',
            'Listopad',
            'Grudzień',
        ],
    };
    const dataPoints = [];
    if (stats) {
        for (const [key] of Object.entries(stats)) {
            dataPoints.push(key);
        }
    }
    dataPoints.sort();
    if (dataPoints.length === 0) {
        return data;
    }
    let lastYear = '0';
    let lastMonth = '0';
    let datasetData = [];
    for (const dataPoint of dataPoints) {
        const month = dataPoint.slice(5, 7);
        const year = dataPoint.slice(0, 4);
        if (lastYear === year) {
            const monthDiff = parseInt(month) - parseInt(lastMonth) - 1;
            for (let i = 0; i < monthDiff; i++) {
                datasetData.push(0);
            }
            datasetData.push(stats[dataPoint]);
        } else {
            if (lastYear === '0') {
            } else {
                data.datasets.push({
                    backgroundColor: theme.palette.background.gradientStartSemi,
                    borderColor: theme.palette.background.gradientStart,
                    label: lastYear,
                    data: datasetData,
                });
                datasetData = [];
            }
            for (let i = 0; i < parseInt(month) - parseInt(lastMonth) - 1; i++) {
                datasetData.push(0);
            }
            datasetData.push(stats[dataPoint]);
        }
        lastYear = year;
        lastMonth = month;
    }
    data.datasets.push({
        backgroundColor: theme.palette.background.gradientEndSemi,
        borderColor: theme.palette.background.gradientEnd,
        label: lastYear,
        data: datasetData,
    });
    return data;
};

const options = {
    animation: false,
    cornerRadius: 15,
    layout: { padding: 0 },
    legend: { display: true, position: 'bottom' },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
        xAxes: [
            {
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                ticks: {
                    fontColor: theme.palette.text.secondary,
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                    beginAtZero: true,
                    min: 0,
                    callback: label => label.toLocaleString('pl-PL'),
                },
                gridLines: {
                    borderDash: [8],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider,
                },
            },
        ],
    },
    tooltips: {
        backgroundColor: theme.palette.background.default,
        bodyFontColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        enabled: true,
        footerFontColor: theme.palette.text.secondary,
        intersect: false,
        mode: 'index',
        titleFontColor: theme.palette.text.primary,
    },
};

const ScansChart = ({ stats, isAdmin, title, ...rest }) => {
    const classes = useStyles();

    return (
        <Card {...rest}>
            <CardHeader
                title={
                    <>
                        <ShowChartIcon color="primary" className={classes.cardTitleIcon} />
                        {title || 'Liczba zeskanowanych kodów QR'}
                    </>
                }
            />
            <CardContent>
                <Box height={!isAdmin ? '440px' : '414px'} position="relative">
                    <Line data={generateChartData(stats)} options={options} />
                </Box>
            </CardContent>
        </Card>
    );
};

ScansChart.propTypes = {
    className: PropTypes.string,
};

export default ScansChart;
