import React, { useContext } from 'react';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { UserContext } from '../UserContext';
import CenteredError from '../components/CenteredError';

function View404() {
    const { user } = useContext(UserContext);
    return (
        <CenteredError title="404: Strony nie znaleziono">
            Niestety, szukana strona nie została znaleziona. Spróbuj{' '}
            <Link
                to={!!user && !!user.token ? '/dashboard' : '/'}
                component={RouterLink}
            >
                przejść do ekranu głównego
            </Link>
            .
        </CenteredError>
    );
}

export default View404;
