import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    Typography,
    makeStyles,
    Chip,
} from '@material-ui/core';
import {
    BarChart as BarChartIcon,
    Settings as SettingsIcon,
    ShoppingBag as ShoppingBagIcon,
    HelpCircle as HelpIcon,
    Users as UsersIcon,
    ExternalLink,
} from 'react-feather';
import NavItem from './NavItem';
import { UserContext } from '../../UserContext';

const items = [
    {
        href: '/dashboard',
        icon: BarChartIcon,
        title: 'Pulpit',
    },
    {
        href: '/users',
        icon: UsersIcon,
        title: 'Użytkownicy',
    },
    {
        href: '/payments',
        icon: ShoppingBagIcon,
        title: 'Zamówienia',
    },
    {
        href: '/invoices',
        icon: ExternalLink,
        title: 'Eksport faktur',
    },
    {
        href: '/settings',
        icon: SettingsIcon,
        title: 'Ustawienia systemu',
    },
    {
        href: '/support',
        icon: HelpIcon,
        title: 'Wsparcie techniczne',
    },
];

const useStyles = makeStyles(theme => ({
    mobileDrawer: {
        width: 240,
    },
    desktopDrawer: {
        width: 260,
        top: 64,
        height: 'calc(100% - 64px)',
    },
    avatar: {
        cursor: 'pointer',
        width: 48,
        height: 48,
        background: theme.palette.background.gradient,
        marginBottom: 12,
    },
    chip: {
        margin: '6px 0',
    },
}));

const SideNav = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();

    const { user } = useContext(UserContext);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <>
            {!!user && (
                <>
                    <Box height="100%" display="flex" flexDirection="column">
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            p={2}
                            pt={3}
                        >
                            <Avatar className={classes.avatar}>
                                {user.firstName[0].toUpperCase()}
                                {user.lastName[0].toUpperCase()}
                            </Avatar>
                            <Typography
                                className={classes.name}
                                color="textPrimary"
                                variant="h5"
                            >
                                {user.firstName + ' ' + user.lastName}
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                                {user.email}
                            </Typography>
                            <Chip
                                label="Administrator"
                                size="small"
                                color="primary"
                                className={classes.chip}
                            />
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <List>
                                {items.map(item => (
                                    <NavItem
                                        href={item.href}
                                        key={item.title}
                                        title={item.title}
                                        icon={item.icon}
                                    />
                                ))}
                            </List>
                        </Box>
                        <Box flexGrow={1} />
                        <Typography
                            align="center"
                            variant="body2"
                            color="textSecondary"
                            style={{ fontSize: '11px' }}
                        >
                            Identyfikator sesji: {user.sessionId}
                        </Typography>
                    </Box>
                </>
            )}
        </>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

SideNav.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

SideNav.defaultProps = {
    onMobileClose: () => {},
    openMobile: false,
};

export default SideNav;
