import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import TopBar from './partials/TopBar';
import { UserContext } from '../UserContext';
import { Redirect } from 'react-router';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 56,
        },
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
    },
}));

const AppLayout = ({ children, preventRedirect }) => {
    const { user } = useContext(UserContext);
    const classes = useStyles();

    if (user && !user.loggedOut && !preventRedirect) {
        return <Redirect to="/dashboard"></Redirect>;
    }

    return (
        <div className={classes.root}>
            <TopBar />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default AppLayout;
