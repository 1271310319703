import { useCallback } from 'react';
import { useContext, useState } from 'react';
import { UserContext } from '../UserContext';

const useAuthApiPost = (url, method = 'POST') => {
    const [state, setState] = useState({
        loading: false,
        data: null,
        error: null,
        success: null,
    });
    const { user } = useContext(UserContext);
    const { data, loading, error, success } = state;

    const callAPI = useCallback(
        data => {
            setState(s => ({ ...s, loading: true }));
            fetch(process.env.REACT_APP_API_ADDR + url, {
                headers: !!user.token
                    ? {
                          Authorization: `Bearer ${user.token}`,
                          'Content-Type': 'application/json',
                      }
                    : { 'Content-Type': 'application/json' },
                method,
                body: JSON.stringify(data),
            })
                .then(async res => {
                    const data = await res.json();
                    if (res.ok) {
                        setState(s => ({
                            ...s,
                            loading: false,
                            error: null,
                            data: data,
                            success: true,
                        }));
                    } else {
                        const msg =
                            res.status === 403
                                ? 'Wystąpił błąd uprawnień użytkownika. Prosimy spróbować ponownie. Jeżeli problem będzie się powtarzał, prosimy o ponowne zalogowanie do systemu.'
                                : data.error || 'Wystąpił błąd.';
                        setState(s => ({
                            ...s,
                            data: null,
                            loading: false,
                            error: msg,
                        }));
                    }
                })
                .catch(err => {
                    setState(s => ({
                        ...s,
                        loading: false,
                        success: false,
                        error:
                            'Wystąpił błąd sieciowy. prosimy od sprawdzenie czy urządzenie znajduje się w trybie online i ponowienie próby.',
                    }));
                });
        },
        [url, user.token, method]
    );

    return { data, loading, error, success, callAPI };
};

export default useAuthApiPost;
