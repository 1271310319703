import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    //useTheme,
    makeStyles,
    TablePagination,
    Tooltip,
    IconButton,
    Button,
    Typography,
} from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GetAppIcon from '@material-ui/icons/GetApp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PaymentStatusChip } from './PaymentStatusChip';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
    downloadButton: {
        marginLeft: '12px',
    },
    cardButton: {
        margin: '10px 0 0 0 ',
        [theme.breakpoints.down('xs')]: {
            marginTop: '30px',
        },
    },
    cardHeader: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}));

const PaymentsTable = ({ className, adminView, data, ...rest }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    return (
        <Card {...rest}>
            <CardHeader
                className={classes.cardHeader}
                title={
                    <>
                        <CreditCardIcon color="primary" className={classes.cardTitleIcon} />
                        Złożone zamówienia na usługi
                    </>
                }
                action={
                    !adminView ? (
                        <Button
                            endIcon={<AddIcon />}
                            component={Link}
                            to="/payments/new"
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.cardButton}
                        >
                            Nowe zamówienie
                        </Button>
                    ) : null
                }
            />
            <CardContent>
                <Box position="relative">
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data złożenia</TableCell>
                                    <TableCell align="center">Status płatności</TableCell>
                                    <TableCell align="right">Wartość brutto</TableCell>
                                    <TableCell align="right">Liczba miesięcy</TableCell>
                                    <TableCell align="right">Faktura VAT</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map(row => (
                                        <TableRow key={row.identifier}>
                                            <TableCell component="th" scope="row">
                                                {dayjs(row.created).format('DD-MM-YYYY HH:mm')}
                                            </TableCell>
                                            <TableCell align="center">
                                                <PaymentStatusChip
                                                    status={row.status}
                                                    date={row.paid || row.created}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                {(row.totalPrice / 100).toLocaleString(
                                                    'pl-PL',
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                ) + ' PLN'}
                                            </TableCell>
                                            <TableCell align="right">{row.months}</TableCell>
                                            <TableCell align="right">
                                                {row.invoiceId || '-'}
                                                {row.invoiceId && (
                                                    <Tooltip title="Pobierz fakturę">
                                                        <IconButton
                                                            size="small"
                                                            className={classes.downloadButton}
                                                            aria-label="delete"
                                                            href={
                                                                process.env
                                                                    .REACT_APP_API_ADDR +
                                                                row.downloadURL
                                                            }
                                                        >
                                                            <GetAppIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {(!data || data.length === 0) && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography variant="body2" align="center">
                                                Konto tego użytkownika nie posiada jeszcze
                                                żadnych zamówień.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[8, 25, 50]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={(event, newPage) => {
                                setPage(newPage);
                            }}
                            onChangeRowsPerPage={event => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                            labelRowsPerPage="Liczba wierszy na stronę"
                            labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} z ${count}`
                            }
                        />
                    </TableContainer>
                </Box>
            </CardContent>
        </Card>
    );
};

PaymentsTable.propTypes = {
    className: PropTypes.string,
};

export default PaymentsTable;
