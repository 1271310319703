import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    colors,
    makeStyles,
    Button,
    MenuItem,
    Menu,
} from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import StatCard from './StatCard';
import CropFreeIcon from '@material-ui/icons/CropFree';
import PrintIcon from '@material-ui/icons/Print';
import dayjs from 'dayjs';
import { formatCategoriesCount } from '../utils';
import { Edit, Visibility } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    avatar: {
        backgroundColor: colors.green[600],
        height: 56,
        width: 56,
    },
    monthlyResultValue: {
        color: theme.palette.primary.main,
    },
    gridFix: {
        margin: 0,
        width: '100%',
    },
}));

const StatCards = ({ data, adminView, onDateClick, onMenuClick }) => {
    const classes = useStyles();
    const currentDate = dayjs();
    const expDate = dayjs(data.restaurant.validUntil);
    const expired = expDate.isBefore(currentDate);
    const noRestaurant = !data?.restaurant?.validUntil;
    const dateDiff = Math.round(expDate.diff(currentDate, 'day', true));
    const [anchor, setAnchor] = useState(null);

    const handleClose = () => {
        setAnchor(null);
    };

    const [totalScans, setTotalScans] = useState(0);
    const [currentMonthScans, setCurrentMontScans] = useState(0);

    useEffect(() => {
        let total = 0;
        let current = 0;
        if (data?.restaurant?.stats) {
            for (const [key, value] of Object.entries(data?.restaurant?.stats)) {
                total += value;
                if (key === dayjs().format('YYYY-MM')) {
                    current = value;
                }
            }
        }
        setTotalScans(total);
        setCurrentMontScans(current);
    }, [data]);

    return (
        <>
            <Grid item lg={4} sm={6} xl={4} xs={12}>
                <StatCard
                    title="Ważność usługi"
                    value={
                        noRestaurant
                            ? '-'
                            : expDate.format(adminView ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY')
                    }
                    info={
                        noRestaurant ? (
                            <>
                                <Typography variant="body2" color="textSecondary">
                                    Usługa zostanie utworzona po aktywacji konta.
                                </Typography>
                            </>
                        ) : !expired ? (
                            <>
                                <Typography variant="body2" color="textSecondary">
                                    <span className={classes.monthlyResultValue}>
                                        {parseInt(dateDiff)}
                                    </span>{' '}
                                    dni do wygaśnięcia usługi
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="body2" color="textSecondary">
                                    <span className={classes.monthlyResultValue}>
                                        Wygasła!
                                    </span>{' '}
                                    Wymagane opłacenie usługi
                                </Typography>
                            </>
                        )
                    }
                    warning={expired}
                    icon={<PaymentIcon />}
                    actionButton={
                        <Button
                            endIcon={<Edit />}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={onDateClick}
                            disabled={noRestaurant}
                        >
                            Zmień
                        </Button>
                    }
                />
            </Grid>
            <Grid item lg={4} sm={6} xl={4} xs={12}>
                <StatCard
                    title={'Zeskanowane kody QR'}
                    value={totalScans}
                    info={
                        <>
                            <Typography variant="body2" color="textSecondary">
                                <span className={classes.monthlyResultValue}>
                                    {currentMonthScans}{' '}
                                </span>
                                w bieżącym miesiącu
                            </Typography>
                        </>
                    }
                    icon={<CropFreeIcon />}
                    actionButton={
                        <>
                            <Button
                                endIcon={<PrintIcon />}
                                color="primary"
                                variant="contained"
                                size="small"
                                disabled={noRestaurant}
                                onClick={event => {
                                    setAnchor(event.currentTarget);
                                }}
                            >
                                {' '}
                                Drukuj kod QR
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchor}
                                keepMounted
                                open={Boolean(anchor)}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <MenuItem
                                    download
                                    component="a"
                                    href={
                                        process.env.REACT_APP_API_ADDR + data.restaurant.pdfURL
                                    }
                                    onClick={handleClose}
                                >
                                    Naklejka (etykiety 16x A8, arkusz A4)
                                </MenuItem>
                                <MenuItem
                                    download
                                    component="a"
                                    href={
                                        process.env.REACT_APP_API_ADDR +
                                        data.restaurant.pdfURL +
                                        '&variant=classic'
                                    }
                                    onClick={handleClose}
                                >
                                    Ulotka stonowana (format A5)
                                </MenuItem>
                                <MenuItem
                                    download
                                    component="a"
                                    href={
                                        process.env.REACT_APP_API_ADDR +
                                        data.restaurant.pdfURL +
                                        '&variant=modern'
                                    }
                                    onClick={handleClose}
                                >
                                    Ulotka kolorowa (format A5)
                                </MenuItem>
                            </Menu>
                        </>
                    }
                />
            </Grid>
            <Grid item lg={4} sm={6} xl={4} xs={12}>
                <StatCard
                    title={'Dania w menu'}
                    value={data?.restaurant?.menuStats?.meals || 0}
                    info={
                        <>
                            <Typography variant="body2" color="textSecondary">
                                <span className={classes.monthlyResultValue}>
                                    {data?.restaurant?.menuStats?.categories || 0}
                                </span>{' '}
                                {formatCategoriesCount(
                                    data?.restaurant?.menuStats?.categories || 0
                                )}{' '}
                                menu
                            </Typography>
                        </>
                    }
                    icon={<MenuBookIcon />}
                    actionButton={
                        <Button
                            endIcon={<Visibility />}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={onMenuClick}
                            disabled={noRestaurant}
                        >
                            Podgląd menu
                        </Button>
                    }
                />
            </Grid>
        </>
    );
};

export default StatCards;
