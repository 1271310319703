import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    //useTheme,
    makeStyles,
    TablePagination,
    Tooltip,
    IconButton,
    Typography,
    Link,
    Button,
} from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GetAppIcon from '@material-ui/icons/GetApp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PaymentStatusChip } from '../PaymentStatusChip';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { FilterList, GetApp } from '@material-ui/icons';
import OrdersListFilterModal from './OrdersListFilterModal';

const useStyles = makeStyles(theme => ({
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
    downloadButton: {
        marginLeft: '12px',
    },
    cardButton: {
        margin: '10px 8px 0 0 ',
        [theme.breakpoints.down('xs')]: {
            marginTop: '30px',
        },
    },
    cardHeader: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    skeletons: {
        width: '100%',
    },
}));

const PaymentsTable = ({
    className,
    data,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalDocs,
    loading,
    filtersApplied,
    onFiltersChanged,
    downloadCsv,
    ...rest
}) => {
    const classes = useStyles();
    const [filterOpen, setFilterOpen] = useState(false);
    return (
        <Card {...rest}>
            <CardHeader
                className={classes.cardHeader}
                title={
                    <>
                        <CreditCardIcon color="primary" className={classes.cardTitleIcon} />
                        Zamówienia na usługi złożone przez użytkowników
                    </>
                }
                action={
                    <>
                        <Button
                            endIcon={<GetApp />}
                            variant="text"
                            size="small"
                            disabled={false}
                            className={classes.cardButton}
                            download
                            href={process.env.REACT_APP_API_ADDR + downloadCsv}
                        >
                            CSV
                        </Button>
                        <Button
                            endIcon={<FilterList />}
                            variant={filtersApplied ? 'contained' : 'outlined'}
                            size="small"
                            color="primary"
                            disabled={false}
                            className={classes.cardButton}
                            onClick={() => {
                                setFilterOpen(true);
                            }}
                        >
                            Filtrowanie zamówień
                        </Button>
                    </>
                }
            />
            <CardContent>
                <Box position="relative">
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data złożenia</TableCell>
                                    <TableCell align="center">Status płatności</TableCell>
                                    <TableCell align="center">Klient</TableCell>
                                    <TableCell align="right">Wartość brutto</TableCell>
                                    <TableCell align="right">Liczba miesięcy</TableCell>
                                    <TableCell align="right">Faktura VAT</TableCell>
                                </TableRow>
                            </TableHead>
                            {loading ? (
                                <TableCell colSpan={6}>
                                    <Skeleton height={59} />
                                    <Skeleton height={59} />
                                    <Skeleton height={59} />
                                </TableCell>
                            ) : (
                                <TableBody>
                                    {data.map(row => (
                                        <TableRow key={row.identifier}>
                                            <TableCell component="th" scope="row">
                                                {dayjs(row.created).format('DD-MM-YYYY HH:mm')}
                                            </TableCell>
                                            <TableCell align="center">
                                                <PaymentStatusChip
                                                    status={row.status}
                                                    date={row.paid || row.created}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Link
                                                    component={RouterLink}
                                                    to={'/users/' + row.user._id}
                                                >
                                                    {row.user.firstName +
                                                        ' ' +
                                                        row.user.lastName}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="right">
                                                {(row.status === 'admin_gift' && '-') ||
                                                    (row.totalPrice / 100).toLocaleString(
                                                        'pl-PL',
                                                        {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) + ' PLN'}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.status === 'admin_gift'
                                                    ? dayjs(row.validUntil).diff(
                                                          row.startDate,
                                                          'd'
                                                      ) + 'd'
                                                    : row.months}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.invoiceId || '-'}
                                                {row.invoiceId && (
                                                    <Tooltip title="Pobierz fakturę">
                                                        <IconButton
                                                            size="small"
                                                            className={classes.downloadButton}
                                                            aria-label="delete"
                                                            href={
                                                                process.env
                                                                    .REACT_APP_API_ADDR +
                                                                row.downloadURL
                                                            }
                                                        >
                                                            <GetAppIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {(!data || data.length === 0) && (
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <Typography variant="body2" align="center">
                                                    Nie znaleziono zamówień.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={totalDocs}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={(event, newPage) => {
                                setPage(newPage);
                            }}
                            onChangeRowsPerPage={event => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                            labelRowsPerPage="Liczba wierszy na stronę"
                            labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} z ${count}`
                            }
                        />
                    </TableContainer>
                </Box>
            </CardContent>
            <OrdersListFilterModal
                onFiltersChanged={newFilters => {
                    onFiltersChanged(newFilters);
                    setFilterOpen(false);
                }}
                isOpen={filterOpen}
            />
        </Card>
    );
};

PaymentsTable.propTypes = {
    className: PropTypes.string,
};

export default PaymentsTable;
