import React, { useState } from 'react';
import useSWR from 'swr';
import DashboardPageContainer from '../layouts/partials/DashboardPageContainer';
import { Typography, Grid, Box, makeStyles } from '@material-ui/core';
import DashboardError from '../components/DashboardError';
import UsersTable from '../components/admin/UsersTable';

const useStyles = makeStyles(() => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
}));

const areFiltersApplied = f => {
    return f.firstName !== '' || f.lastName !== '' || f.email !== '' || f.status !== '';
};

const initialFilters = {
    firstName: '',
    lastName: '',
    email: '',
    status: '',
};

const UsersView = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [filters, setFilters] = useState(initialFilters);
    const { data, error } = useSWR(
        `admin/user?page=${page + 1}&limit=${rowsPerPage}&firstName=${
            filters.firstName
        }&lastName=${filters.lastName}&email=${filters.email}&status=${filters.status}`
    );
    if (!!error) {
        return <DashboardError error={error} />;
    }
    return (
        <DashboardPageContainer>
            <Box ml={2} mb={2} mt={2}>
                <Typography variant="h1">Użytkownicy</Typography>
            </Box>

            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xl={12} lg={12} xs={12}>
                    <UsersTable
                        loading={!data && !error}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        page={page}
                        setPage={setPage}
                        data={data?.docs || []}
                        totalDocs={data?.totalDocs || 0}
                        totalPages={data?.totalPages || 1}
                        onFiltersChanged={newFilters => {
                            setFilters({ ...initialFilters, ...newFilters });
                            setPage(0);
                        }}
                        filtersApplied={areFiltersApplied(filters)}
                        downloadCsv={`admin/user/downloadcsv?firstName=${filters.firstName}&lastName=${filters.lastName}&email=${filters.email}&status=${filters.status}&token=${data?.downloadToken}&ttl=${data?.downloadTTL}`}
                    ></UsersTable>
                </Grid>
            </Grid>
        </DashboardPageContainer>
    );
};

export default UsersView;
