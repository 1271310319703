import React, { useState, useContext } from 'react';
import DashboardBottomCard from '../DashboardBottomCard';
import {
    TextField,
    makeStyles,
    Box,
    Button,
    Typography,
    InputAdornment,
    Grid,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import SaveIcon from '@material-ui/icons/Save';
import { UserContext } from '../../UserContext';
import CheckIcon from '@material-ui/icons/Check';
import Alert from '@material-ui/lab/Alert';
import { Settings } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    postCode: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '57%',
        },
    },
    saved: {
        paddingLeft: '12px',
    },
    savedIcon: {
        paddingLeft: 6,
        paddingBottom: 6,
    },
}));

export const normalizePrice = value => {
    let normalized = value.replace(/\D/g, '').replace(/\b0+/g, '');
    const len = normalized.length;
    if (len === 0) {
        return '0.00';
    }
    if (len === 1) {
        return '0.0' + normalized;
    }
    if (len === 2) {
        return '0.' + normalized;
    }
    return normalized.slice(0, len - 2) + '.' + normalized.slice(len - 2, len);
};

const normalizeVAT = value => value.replace(/\D/g, '');

const SettingsCard = ({ data, mutate }) => {
    const classes = useStyles();
    const { register, reset, handleSubmit, errors } = useForm();
    const [state, setState] = useState();
    const [form, setForm] = useState({ loading: false, error: null, done: false });
    const { user } = useContext(UserContext);

    const updateSettings = async data => {
        setForm({ ...form, loading: true, error: null, done: false });
        fetch(process.env.REACT_APP_API_ADDR + 'admin/settings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`,
            },
            body: JSON.stringify({
                ...data,
                price: data.price.replace(/\D/g, ''),
                priceHotel: data.priceHotel.replace(/\D/g, ''),
                priceBar: data.priceBar.replace(/\D/g, ''),
                activation: data.activation.replace(/\D/g, ''),
                activationHotel: data.activationHotel.replace(/\D/g, ''),
                activationBar: data.activationBar.replace(/\D/g, ''),
            }),
        })
            .then(async response => {
                if (!response.ok) {
                    const data = await response.json();
                    const error = data.error;
                    setForm({
                        ...form,
                        submitting: false,
                        error:
                            error ||
                            'Wystąpił błąd przy obsłudze zapytania. Prosimy spróbować ponownie.',
                        done: true,
                    });
                } else {
                    setForm({
                        ...form,
                        loading: false,
                        error: null,
                        done: true,
                    });
                }
                mutate(data => data, true);
            })
            .catch(error => {
                setForm({
                    ...form,
                    done: true,
                    loading: false,
                    error:
                        'Wystąpił błąd sieciowy. Prosimy upewnić się że urządzenie jest w trybie online i spróbowac ponownie.',
                });
                mutate(data => data, true);
            });
    };

    if (!state) {
        setState(data);
        reset({
            ...data,
            price: normalizePrice(data.price.toString()),
            priceBar: normalizePrice(data.priceBar.toString()),
            priceHotel: normalizePrice(data.priceHotel.toString()),
            activation: normalizePrice(data.activation.toString()),
            activationBar: normalizePrice(data.activationBar.toString()),
            activationHotel: normalizePrice(data.activationHotel.toString()),
        });
    }

    return (
        <DashboardBottomCard title="Ustawienia systemu" icon={<Settings />}>
            <form
                onSubmit={handleSubmit(data => {
                    updateSettings(data);
                })}
            >
                <Grid container justify="space-between">
                    <Grid item xs={12} sm={7} className={classes.postCode}>
                        <TextField
                            fullWidth
                            label="Miesięczna cena: Restauracja"
                            margin="normal"
                            type="tel"
                            name="price"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 1,
                                maxLength: 6,
                            })}
                            error={!!errors.price}
                            helperText={errors.price ? 'Podanie ceny jest wymagane' : null}
                            inputProps={{ style: { textAlign: 'right' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">PLN</InputAdornment>
                                ),
                            }}
                            onChange={e => {
                                const { value } = e.target;
                                e.target.value = normalizePrice(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            fullWidth
                            label="Aktywacja"
                            margin="normal"
                            type="tel"
                            name="activation"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 1,
                                maxLength: 6,
                            })}
                            error={!!errors.activation}
                            helperText={
                                errors.activation ? 'Podanie ceny jest wymagane' : null
                            }
                            inputProps={{ style: { textAlign: 'right' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">PLN</InputAdornment>
                                ),
                            }}
                            onChange={e => {
                                const { value } = e.target;
                                e.target.value = normalizePrice(value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="space-between">
                    <Grid item xs={12} sm={7} className={classes.postCode}>
                        <TextField
                            fullWidth
                            label="Miesięczna cena: Bar"
                            margin="normal"
                            type="tel"
                            name="priceBar"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 1,
                                maxLength: 6,
                            })}
                            error={!!errors.priceBar}
                            helperText={errors.priceBar ? 'Podanie ceny jest wymagane' : null}
                            inputProps={{ style: { textAlign: 'right' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">PLN</InputAdornment>
                                ),
                            }}
                            onChange={e => {
                                const { value } = e.target;
                                e.target.value = normalizePrice(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            fullWidth
                            label="Aktywacja"
                            margin="normal"
                            type="tel"
                            name="activationBar"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 1,
                                maxLength: 6,
                            })}
                            error={!!errors.activationBar}
                            helperText={
                                errors.activationBar ? 'Podanie ceny jest wymagane' : null
                            }
                            inputProps={{ style: { textAlign: 'right' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">PLN</InputAdornment>
                                ),
                            }}
                            onChange={e => {
                                const { value } = e.target;
                                e.target.value = normalizePrice(value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="space-between">
                    <Grid item xs={12} sm={7} className={classes.postCode}>
                        <TextField
                            fullWidth
                            label="Miesięczna cena: Hotel"
                            margin="normal"
                            type="tel"
                            name="priceHotel"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 1,
                                maxLength: 6,
                            })}
                            error={!!errors.priceHotel}
                            helperText={
                                errors.priceHotel ? 'Podanie ceny jest wymagane' : null
                            }
                            inputProps={{ style: { textAlign: 'right' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">PLN</InputAdornment>
                                ),
                            }}
                            onChange={e => {
                                const { value } = e.target;
                                e.target.value = normalizePrice(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            fullWidth
                            label="Aktywacja"
                            margin="normal"
                            type="tel"
                            name="activationHotel"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 1,
                                maxLength: 6,
                            })}
                            error={!!errors.activationHotel}
                            helperText={
                                errors.activationHotel ? 'Podanie ceny jest wymagane' : null
                            }
                            inputProps={{ style: { textAlign: 'right' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">PLN</InputAdornment>
                                ),
                            }}
                            onChange={e => {
                                const { value } = e.target;
                                e.target.value = normalizePrice(value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="space-between">
                    <Grid item xs={12} sm={7} className={classes.postCode}></Grid>
                    <Grid item xs={7} sm={5}>
                        <TextField
                            fullWidth
                            label="Stawka VAT"
                            margin="normal"
                            name="vat"
                            type="tel"
                            variant="outlined"
                            inputRef={register({
                                required: {
                                    value: true,
                                    message: 'Podanie stawki VAT jest wymagane.',
                                },
                                minLength: 1,
                                maxLength: 2,
                            })}
                            error={!!errors.billingAddress}
                            helperText={
                                errors.billingAddress ? 'Podanie adresu jest wymagane' : null
                            }
                            inputProps={{ style: { textAlign: 'right' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">%</InputAdornment>
                                ),
                            }}
                            onChange={e => {
                                const { value } = e.target;
                                e.target.value = normalizeVAT(value);
                            }}
                        />
                    </Grid>
                </Grid>
                {form.done && form.error && <Alert severity="error">{form.error}</Alert>}
                <Box my={2} display="flex" alignItems="center">
                    <Button
                        color="primary"
                        size="medium"
                        type="submit"
                        variant="contained"
                        endIcon={<SaveIcon />}
                        disabled={form.loading}
                    >
                        Zapisz
                    </Button>
                    {!form.error && form.done && (
                        <>
                            <Typography variant="body2" className={classes.saved}>
                                Zapisano zmiany
                            </Typography>
                            <CheckIcon color="primary" className={classes.savedIcon} />
                        </>
                    )}
                </Box>
            </form>
        </DashboardBottomCard>
    );
};

export default SettingsCard;
