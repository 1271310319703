import React from 'react';
import Chip from '@material-ui/core/Chip';
import { Tooltip } from '@material-ui/core';
import dayjs from 'dayjs';
import { Cancel, Done } from '@material-ui/icons';

export const RestaurantStatusChip = ({ date }) => {
    const dDate = dayjs(date);
    const expired = dDate.isBefore(dayjs());
    const shortDate = dDate.format('DD-MM-YYYY');
    const longDate = dDate.format('DD-MM-YYYY HH:mm');
    return (
        <Tooltip
            title={!expired ? 'Usługa aktywna do ' + longDate : 'Usługa wygasła ' + longDate}
        >
            <Chip
                icon={expired ? <Cancel /> : <Done />}
                label={shortDate}
                color={expired ? 'primary' : 'default'}
            />
        </Tooltip>
    );
};
