import React, { useEffect } from 'react';
import { useState, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppLayout from './layouts/AppLayout';
import LoginView from './views/LoginView';
import './App.css';
import View404 from './views/404';
import DashboardView from './views/DashboardView';
import { UserContext } from './UserContext';
import jwtDecoder from 'jwt-decode';
import DashboardLayout from './layouts/DashboardLayout';
import SupportView from './views/SupportView';
import { SWRConfig } from 'swr';
import SettingsView from './views/SettingsView';
import OrdersView from './views/OrdersView';
import InvoicesView from './views/InvoicesView';
import UsersView from './views/UsersView';
import UserDetailsView from './views/UserDetailsView';
import { AllergensContext } from './AllergensContext';

function App() {
    const [user, setUser] = useState(() => {
        const token = localStorage.getItem('token');
        if (token === null) {
            return { loggedOut: true };
        } else {
            const decoded = jwtDecoder(token);
            return { ...decoded, token: token, loggedOut: false };
        }
    });
    const value = useMemo(() => ({ user, setUser }), [user, setUser]);

    const [allergensData, setAllergens] = useState(() => ({ loaded: false }));

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ADDR + 'api/allergens').then(async response => {
            const allergens = await response.json();

            const allAllergensFlattened = {};

            for (var key in allergens) {
                if (allergens.hasOwnProperty(key)) {
                    for (var key2 in allergens[key].members) {
                        if (allergens[key].members.hasOwnProperty(key2)) {
                            allAllergensFlattened[key2] = allergens[key].members[key2];
                        }
                    }
                }
            }

            setAllergens({
                loaded: true,
                allAllergensFlattened,
            });
        });
    }, []);

    return (
        <SWRConfig
            value={{
                fetcher: async url => {
                    const response = await fetch(process.env.REACT_APP_API_ADDR + url, {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    const data = await response.json();
                    if (response.ok) {
                        return data;
                    } else {
                        if (response.status === 403) {
                            localStorage.removeItem('token');
                            setUser({
                                loggedOut: true,
                                permissionsError: true,
                            });
                        }
                        const error = new Error('Wystąpił błąd podczas obsługi zapytania.');
                        error.status = response.status;
                        error.msg = data.error;
                        throw error;
                    }
                },
            }}
        >
            <UserContext.Provider value={value}>
                <AllergensContext.Provider value={allergensData}>
                    <Switch>
                        <Route exact path="/">
                            <AppLayout>
                                <LoginView></LoginView>
                            </AppLayout>
                        </Route>
                        <Route exact path="/dashboard">
                            <DashboardLayout>
                                <DashboardView></DashboardView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/users">
                            <DashboardLayout>
                                <UsersView></UsersView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/users/:id">
                            <DashboardLayout>
                                <UserDetailsView />
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/payments">
                            <DashboardLayout>
                                <OrdersView></OrdersView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/invoices">
                            <DashboardLayout>
                                <InvoicesView></InvoicesView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/support">
                            <DashboardLayout>
                                <SupportView></SupportView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/settings">
                            <DashboardLayout>
                                <SettingsView></SettingsView>
                            </DashboardLayout>
                        </Route>
                        <Route path="*">
                            <AppLayout preventRedirect={true}>
                                <View404></View404>
                            </AppLayout>
                        </Route>
                    </Switch>
                </AllergensContext.Provider>
            </UserContext.Provider>
        </SWRConfig>
    );
}

export default App;
