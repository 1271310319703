import React, { useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import jwtDecoder from 'jwt-decode';

import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';
import { Lock } from '@material-ui/icons';

const LoginView = () => {
    const { register, handleSubmit } = useForm();

    const [state, setState] = useState({ submitting: false, error: null, popup: false });

    const [data, setData] = useState({ email: '', password: '', otp: '' });

    const history = useHistory();

    const { user, setUser } = useContext(UserContext);

    const apiLoginCall = async (email, password, otp) => {
        setState({ ...state, submitting: true });
        fetch(process.env.REACT_APP_API_ADDR + 'auth/adminlogin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password, otp }),
        })
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    console.log(data);
                    if (data?.error?.startsWith('OTP')) {
                        setState({
                            ...state,
                            submitting: false,
                            error: null,
                            popup: true,
                            submittingOtp: false,
                        });
                    } else if (data?.error?.startsWith('Wrong')) {
                        setState({ ...state, otpError: true, submittingOtp: false });
                    } else {
                        setState({ ...state, submitting: false, error: data.error });
                    }
                } else {
                    setState({
                        ...state,
                        submitting: false,
                        error: null,
                        loggedOut: false,
                    });
                    const decoded = jwtDecoder(data.token);
                    setUser({ ...decoded, token: data.token });
                    localStorage.setItem('token', data.token);
                    history.push('/dashboard');
                }
            })
            .catch(error => {
                setState({
                    ...state,
                    popup: false,
                    submitting: false,
                    submittingOtp: false,
                    error:
                        'Wystąpił błąd sieciowy. Prosimy upewnić się że urządzenie jest w trybie online i spróbowac ponownie.',
                });
            });
    };

    const handleOtpSubmit = () => {
        setState({ ...state, submittingOtp: true });
        apiLoginCall(data.email, data.password, data.otp);
    };

    return (
        <VerticallyCenteredSubLayout>
            <img
                style={{ width: '70%', margin: '0 auto', display: 'block' }}
                src="/foodinly_logo_crop.svg"
                alt="logo foodinly"
            ></img>
            <form
                onSubmit={handleSubmit(data => {
                    setData({ ...data, otp: '' });
                    apiLoginCall(data.email, data.password);
                })}
            >
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2" align="center">
                        Logowanie administratora
                    </Typography>
                </Box>
                <Box mt={3} mb={1}>
                    <Typography align="center" color="textSecondary" variant="body1">
                        Zaloguj się, aby zarządzać aplikacją
                    </Typography>
                    {state.error && (
                        <Box mt={3} mb={1}>
                            <Alert severity="error">{state.error}</Alert>
                        </Box>
                    )}
                    {user?.justLoggedOut && !state.error && (
                        <Box mt={3} mb={1}>
                            <Alert severity="success">
                                Wylogowano poprawnie. Zapraszamy ponownie!
                            </Alert>
                        </Box>
                    )}
                    {user?.permissionsError && (
                        <Box mt={3} mb={1}>
                            <Alert severity="error">
                                Wykryto niedozwolone działanie. Wylogowano.
                            </Alert>
                        </Box>
                    )}
                </Box>
                <TextField
                    fullWidth
                    label="Adres e-mail"
                    margin="normal"
                    name="email"
                    type="email"
                    variant="outlined"
                    inputRef={register({ required: true })}
                />
                <TextField
                    fullWidth
                    label="Hasło"
                    margin="normal"
                    name="password"
                    type="password"
                    variant="outlined"
                    inputRef={register({ required: true })}
                />
                <Box my={2}>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={state.submitting}
                    >
                        Zaloguj się{' '}
                        {state.submitting && <CircularProgress size={16}></CircularProgress>}
                    </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                    Funkcjonalność odzyskiwania hasła i rejestracji jest niedostępna dla
                    administratora systemu.
                </Typography>
            </form>
            <Dialog maxWidth="xs" open={state.popup}>
                <DialogTitle>
                    <Lock
                        color="primary"
                        style={{ verticalAlign: '-5px', paddingRight: '5px' }}
                    />
                    Uwierzytelnianie dwuskładnikowe
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Aby zalogować się do systemu, przepisz token z aplikacji mobilnej.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Mobilny token uwierzytelniający"
                        variant="outlined"
                        fullWidth
                        value={data.otp}
                        onChange={e => {
                            setData({ ...data, otp: e.target.value });
                            setState({ ...state, otpError: false });
                        }}
                        error={state.otpError}
                        helperText={state.otpError ? 'Nieprawidłowy token' : null}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                handleOtpSubmit();
                            }
                        }}
                        disabled={state.submittingOtp}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={state.submittingOtp}
                        onClick={() => {
                            setData({ ...data, otp: '' });
                            setState({ ...state, popup: false });
                        }}
                    >
                        Anuluj
                    </Button>
                    <Button
                        onClick={handleOtpSubmit}
                        disabled={
                            (data.otp.length !== 8 && data.otp.length !== 6) ||
                            state.submittingOtp
                        }
                        color="primary"
                    >
                        Dalej
                    </Button>
                </DialogActions>
            </Dialog>
        </VerticallyCenteredSubLayout>
    );
};

export default LoginView;
