import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import DashboardBottomCard from './DashboardBottomCard';
import { AccountDataList } from './AccountDataList';

const AccountDataCard = ({ isAdmin, data, onClicked }) => {
    return (
        <DashboardBottomCard title="Dane konta" icon={<PersonIcon />}>
            <AccountDataList data={data} isAdmin={isAdmin} handleClick={onClicked} />
        </DashboardBottomCard>
    );
};

export default AccountDataCard;
