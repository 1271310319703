import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Typography,
} from '@material-ui/core';
import React, { Fragment, useContext, useState } from 'react';
import { AllergensContext } from '../../AllergensContext';

const pickTranslation = s => {
    return s ? s.split('|')[0] : 'WYCOFANY';
};
const pickTranslationOther = s => {
    return s.split('|')[1];
};

const useStyles = makeStyles(theme => ({
    wrapper: {
        maxWidth: '500px',
        margin: '0 auto',
    },
    infoBox: {
        background: theme.palette.background.dark,
        borderRadius: '5px',
    },
    dismissButton: {
        marginLeft: '6px',
    },
    title: {
        marginLeft: '12px',
        marginTop: '12px',
    },
    labelEmptyCategory: {
        paddingRight: '25px',
    },
    tab: {
        flexDirection: 'row',
    },
    tabIcon: {
        paddingLeft: 4,
        paddingBottom: 3,
    },
    allergensWrapper: {
        marginTop: 8,
    },
    chip: {
        marginRight: 4,
        marginBottom: 4,
    },
    anotherLangName: {
        fontStyle: 'italic',
    },
}));

const AllergenList = ({ collisions, allergens }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    return (
        <div>
            {collisions.slice(0, expanded ? collisions.length : 5).map(allergen => (
                <Chip
                    color="primary"
                    size="small"
                    label={pickTranslation(allergens[allergen]) || allergen}
                    key={allergen}
                    className={classes.chip}
                />
            ))}
            {!expanded && collisions.length > 5 && (
                <Chip
                    size="small"
                    label="..."
                    onClick={() => {
                        setExpanded(true);
                    }}
                    className={classes.chip}
                />
            )}
        </div>
    );
};

const MealsList = ({ meals }) => {
    const { loaded, allAllergensFlattened: allergens } = useContext(AllergensContext);
    const classes = useStyles();
    return (
        loaded && (
            <>
                {meals.map(el => (
                    <div key={el._id}>
                        <Typography className={classes.title} variant="h3">
                            {pickTranslation(el.category)}
                        </Typography>
                        {el.meals.length === 0 && (
                            <List>
                                <ListItem>
                                    <ListItemText secondary="Nie zdefiniowano dań w tej kategorii." />
                                </ListItem>
                            </List>
                        )}
                        {el.meals.length > 0 && (
                            <List>
                                {el.meals.map((meal, idx) => (
                                    <Fragment key={meal._id}>
                                        <ListItem>
                                            <ListItemText
                                                primary={pickTranslation(meal.name)}
                                                secondary={
                                                    <>
                                                        <span
                                                            className={classes.anotherLangName}
                                                        >
                                                            {pickTranslationOther(meal.name)}
                                                        </span>
                                                        <div
                                                            className={
                                                                classes.allergensWrapper
                                                            }
                                                        >
                                                            <AllergenList
                                                                collisions={meal.allergens}
                                                                allergens={allergens}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                    </Fragment>
                                ))}
                            </List>
                        )}
                    </div>
                ))}
            </>
        )
    );
};

const MenuModal = ({ menu, isOpen, close }) => (
    <Dialog open={isOpen} onClose={close}>
        <DialogTitle>Menu restauracji</DialogTitle>
        <DialogContent>
            <MealsList meals={menu} />
        </DialogContent>

        <DialogActions>
            <Button onClick={close}>Zamknij</Button>
        </DialogActions>
    </Dialog>
);

export default MenuModal;
