import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
} from '@material-ui/core';
import { Home } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import dayjs from 'dayjs';
import React from 'react';
import useSWR from 'swr';

const useStyles = makeStyles(theme => ({
    modal: {
        minWidth: '300px',
    },
    textInput: {
        marginBottom: theme.spacing(2),
    },
    listItem: {
        paddingLeft: '25px',
    },
}));

const ChainListModal = ({ isOpen, close, defaultData, id }) => {
    const classes = useStyles();

    const { data, loading } = useSWR(() =>
        isOpen ? 'admin/user/' + id + '/restaurants' : null
    );

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={close}>
            <DialogTitle className={classes.modal}>Lokale gastronomiczne w sieci</DialogTitle>
            <DialogContent>{loading && !data && <Skeleton height="200px" />}</DialogContent>
            {!!data && (
                <List>
                    {data.map(r => (
                        <ListItem key={r._id} className={classes.listItem}>
                            <ListItemText
                                primary={r.name + ` (${r.codeId})`}
                                secondary={
                                    'Utworzono: ' + dayjs(r.created).format('DD-MM-YYYY')
                                }
                            />
                            {!r.parent && (
                                <ListItemIcon>
                                    <Home color="primary" />
                                </ListItemIcon>
                            )}
                        </ListItem>
                    ))}
                </List>
            )}
            <DialogActions>
                <Button onClick={close}>Wróć</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChainListModal;
