import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';
import { normalizePrice } from './SettingsCard';
import plLocale from 'dayjs/locale/pl';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import { Clear } from '@material-ui/icons';

class LocalizedUtils extends DayjsUtils {
    getDatePickerHeaderText(date) {
        return dayjs(date).format('DD-MM-YYYY');
    }
}

const useStyles = makeStyles(theme => ({
    modal: {
        minWidth: '300px',
    },
    textInput: {
        marginBottom: theme.spacing(2),
    },
    firstInput: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '49%',
        },
    },
    datesContainer: {
        marginTop: theme.spacing(2),
    },
}));

export const initialFilters = {
    createdFrom: '',
    createdTo: '',
    status: '',
    totalPriceFrom: '',
    totalPriceTo: '',
    invoiceId: '',
};

const stripDot = price => price.replace(/\./, '');

const OrdersListFilterModal = ({ isOpen, close, onFiltersChanged }) => {
    const classes = useStyles();
    const [filters, setFilters] = useState(initialFilters);
    return (
        <Dialog maxWidth="sm" open={isOpen} onClose={close}>
            <DialogTitle className={classes.modal}>Filtrowanie zamówień</DialogTitle>
            <DialogContent>
                <Grid container justify="space-between">
                    <Grid item xs={12} sm={6} className={classes.firstInput}>
                        <TextField
                            onChange={e => {
                                setFilters({ ...filters, invoiceId: e.target.value });
                            }}
                            variant="outlined"
                            fullWidth={true}
                            value={filters.invoiceId}
                            label="Numer faktury"
                            className={classes.textInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            className={classes.form}
                            fullWidth={true}
                            variant="outlined"
                        >
                            <InputLabel>Status płatności</InputLabel>
                            <Select
                                value={filters.status}
                                onChange={e => {
                                    setFilters({ ...filters, status: e.target.value });
                                }}
                                label="Status płatności"
                            >
                                <MenuItem value={''}>Dowolny</MenuItem>
                                <MenuItem value={'new'}>W toku</MenuItem>
                                <MenuItem value={'completed'}>Dokonana</MenuItem>
                                <MenuItem value={'canceled'}>Odrzucona</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container justify="space-between">
                    <Grid item xs={12} sm={6} className={classes.firstInput}>
                        <TextField
                            fullWidth
                            label="Wartość brutto od"
                            margin="normal"
                            type="tel"
                            name="totalPriceFrom"
                            variant="outlined"
                            inputProps={{ style: { textAlign: 'right' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">PLN</InputAdornment>
                                ),
                            }}
                            value={filters.totalPriceFrom}
                            onChange={e => {
                                const { value } = e.target;
                                const normalized = normalizePrice(value);
                                setFilters({ ...filters, totalPriceFrom: normalized });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Wartość brutto do"
                            margin="normal"
                            type="tel"
                            name="totalPriceTo"
                            variant="outlined"
                            inputProps={{ style: { textAlign: 'right' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">PLN</InputAdornment>
                                ),
                            }}
                            value={filters.totalPriceTo}
                            onChange={e => {
                                const { value } = e.target;
                                const normalized = normalizePrice(value);
                                setFilters({ ...filters, totalPriceTo: normalized });
                            }}
                        />
                    </Grid>
                </Grid>
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                    <Grid className={classes.datesContainer} container justify="space-between">
                        <Grid item xs={12} sm={6} className={classes.firstInput}>
                            <DatePicker
                                label="Data od"
                                inputVariant="outlined"
                                value={filters.createdFrom === '' ? null : filters.createdFrom}
                                onChange={newValue => {
                                    setFilters({ ...filters, createdFrom: newValue });
                                }}
                                ampm={false}
                                formatDate={date => {
                                    dayjs(date).format('DD-MM-YYYY');
                                }}
                                format="DD-MM-YYYY"
                                okLabel="Zatwierdź"
                                cancelLabel="Anuluj"
                                fullWidth="true"
                                InputProps={{
                                    startAdornment: (
                                        <IconButton
                                            onClick={e => {
                                                setFilters({ ...filters, createdFrom: '' });
                                                e.stopPropagation();
                                            }}
                                            disabled={filters.createdFrom === ''}
                                            style={{ order: 1 }}
                                        >
                                            <Clear color="disabled" fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                                InputAdornmentProps={{
                                    position: 'end',
                                    style: { order: 2, marginLeft: 0 },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                fullWidth={true}
                                label="Data do"
                                inputVariant="outlined"
                                value={filters.createdTo === '' ? null : filters.createdTo}
                                onChange={newValue => {
                                    setFilters({ ...filters, createdTo: newValue });
                                }}
                                ampm={false}
                                formatDate={date => {
                                    dayjs(date).format('DD-MM-YYYY');
                                }}
                                format="DD-MM-YYYY"
                                okLabel="Zatwierdź"
                                cancelLabel="Anuluj"
                                InputProps={{
                                    startAdornment: (
                                        <IconButton
                                            onClick={e => {
                                                setFilters({ ...filters, createdTo: '' });
                                                e.stopPropagation();
                                            }}
                                            disabled={filters.createdTo === ''}
                                            style={{ order: 1 }}
                                        >
                                            <Clear fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                                InputAdornmentProps={{
                                    position: 'end',
                                    style: { order: 2, marginLeft: 0 },
                                }}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => {
                        setFilters(initialFilters);
                        onFiltersChanged(initialFilters);
                    }}
                >
                    Wyczyść filtry
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        onFiltersChanged({
                            ...filters,
                            totalPriceFrom: stripDot(filters.totalPriceFrom),
                            totalPriceTo: stripDot(filters.totalPriceTo),
                        });
                    }}
                >
                    Zastosuj
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrdersListFilterModal;
