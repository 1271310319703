import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    makeStyles,
    TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAuthApiPost from '../../hooks/useAuthApiPost';
import { normalizePrice } from './SettingsCard';

const useStyles = makeStyles(theme => ({
    modal: {
        minWidth: '300px',
    },
    textInput: {
        marginBottom: theme.spacing(2),
    },
}));

const CustomPriceModal = ({ isOpen, close, defaultData, id }) => {
    const classes = useStyles();
    const { register, reset, handleSubmit, errors } = useForm();

    const { loading, error, callAPI, success, data } = useAuthApiPost('admin/user/', 'PATCH');

    const onSubmit = data => {
        const transformedData = {
            id,
            ...data,
            activationPrice: parseInt(data.activationPrice.replace('.', '')),
            monthlyPrice: parseInt(data.monthlyPrice.replace('.', '')),
        };
        callAPI(transformedData);
    };

    useEffect(() => {
        reset({
            ...defaultData,
            activationPrice: normalizePrice((defaultData.activationPrice || 0).toString()),
            monthlyPrice: normalizePrice((defaultData.monthlyPrice || 0).toString()),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!loading && success && data) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, success, error, data]);

    return (
        <Dialog maxWidth="sm" open={isOpen} onClose={close}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle className={classes.modal}>
                    Edytuj niestandardową ofertę
                </DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" style={{ marginBottom: '30px' }}>
                            {error}
                        </Alert>
                    )}
                    <TextField
                        variant="outlined"
                        fullWidth={true}
                        label="Maksymalna liczba lokali"
                        name="maxPlaces"
                        inputRef={register({
                            required: true,
                            valueAsNumber: true,
                        })}
                        error={!!errors.maxPlaces}
                        helperText={errors.maxPlaces ? 'Podanie limitu jest wymagane' : null}
                        className={classes.textInput}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        type="number"
                    />
                    <Grid container justify="space-between" spacing={2}>
                        <Grid item xs={12} sm={6} className={classes.postCode}>
                            <TextField
                                fullWidth
                                label="Opłata aktywacyjna"
                                margin="normal"
                                type="tel"
                                name="activationPrice"
                                variant="outlined"
                                inputRef={register({
                                    required: true,
                                    minLength: 1,
                                    maxLength: 10,
                                })}
                                error={!!errors.activationPrice}
                                helperText={
                                    errors.activationPrice
                                        ? 'Wymagane jest podanie ceny'
                                        : null
                                }
                                inputProps={{ style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">PLN</InputAdornment>
                                    ),
                                }}
                                onChange={e => {
                                    const { value } = e.target;
                                    e.target.value = normalizePrice(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Miesięczna cena usługi"
                                margin="normal"
                                type="tel"
                                name="monthlyPrice"
                                variant="outlined"
                                inputRef={register({
                                    required: true,
                                    minLength: 1,
                                    maxLength: 10,
                                })}
                                error={!!errors.monthlyPrice}
                                helperText={
                                    errors.monthlyPrice ? 'Podanie ceny jest wymagane' : null
                                }
                                inputProps={{ style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">PLN</InputAdornment>
                                    ),
                                }}
                                onChange={e => {
                                    const { value } = e.target;
                                    e.target.value = normalizePrice(value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            close();
                        }}
                    >
                        Anuluj
                    </Button>
                    <Button color="primary" type="submit" disabled={loading}>
                        Zapisz
                        {loading && (
                            <>
                                {' '}
                                <CircularProgress size={10} />
                            </>
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CustomPriceModal;
