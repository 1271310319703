import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import SideNav from './partials/SideNav';
import TopBar from './partials/TopBar';
import { UserContext } from '../UserContext';
import { Redirect } from 'react-router';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 260,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 56,
        },
        backgroundColor: '#f4f4f4',
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
    },
}));

const DashboardLayout = ({ children }) => {
    const classes = useStyles();

    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    const { user } = useContext(UserContext);

    if (!user || user.loggedOut) {
        return <Redirect to="/"></Redirect>;
    }

    return (
        <div className={classes.root}>
            <TopBar
                navEnabled={true}
                toggleSideNav={() => {
                    setMobileNavOpen(!isMobileNavOpen);
                }}
            ></TopBar>

            <SideNav
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            />

            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;
