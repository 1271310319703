import React, { useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import { UserContext } from '../../UserContext';
import { AppBar, Toolbar, Box, IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    menuButton: {
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    appLogoWrapper: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            textAlign: 'center',
        },
        '& img': {
            height: 38,

            [theme.breakpoints.down('md')]: {
                height: 32,
            },
        },
    },
    toolbar: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: 6,
            paddingRight: 6,
        },
    },
}));

const TopBar = ({ children, navEnabled, toggleSideNav }) => {
    const { user, setUser } = useContext(UserContext);

    const history = useHistory();

    const classes = useStyles();

    return (
        <AppBar
            elevation={0}
            style={{
                backgroundImage: 'linear-gradient(-45deg, #ff011d, #ff00db)',
            }}
        >
            <Toolbar className={classes.toolbar}>
                {navEnabled && (
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        onClick={toggleSideNav}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <RouterLink
                    className={classes.appLogoWrapper}
                    to={!user?.loggedOut ? '/dashboard' : '/'}
                >
                    <img alt="Foodinly logo" src="/foodinly_logo_horizontal_white.svg"></img>
                </RouterLink>
                <Box flexGrow={1}></Box>
                {!!user && !user.loggedOut && (
                    <IconButton
                        color="inherit"
                        onClick={() => {
                            localStorage.removeItem('token');
                            setUser({
                                loggedOut: true,
                                justLoggedOut: true,
                            });
                            history.push('/');
                        }}
                    >
                        <InputIcon />
                    </IconButton>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
