import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, ListItemSecondaryAction, Tooltip } from '@material-ui/core';
import { Edit, Visibility } from '@material-ui/icons';

const restaurantTypes = {
    bar: 'Bar',
    restaurant: 'Restauracja',
    hotel: 'Hotel',
    chain: 'Sieć',
};

export const BillingDataList = ({ data, onCustomPriceOpen, onChainListOpen }) => (
    <List>
        <ListItem>
            <ListItemText primary={data.billingCompany || '-'} secondary="Nazwa firmy" />
        </ListItem>
        <ListItem>
            <ListItemText primary={data.billingNIP || '-'} secondary="Numer NIP" />
        </ListItem>
        <ListItem>
            <ListItemText primary={data.billingAddress || '-'} secondary="Adres" />
        </ListItem>
        <ListItem>
            <ListItemText
                primary={(data.billingPostCode || '-') + ' ' + (data.billingCity || '-')}
                secondary="Kod pocztowy i miasto"
            />
        </ListItem>
        {data.restaurantType !== 'chain' && (
            <ListItem>
                <ListItemText
                    primary={restaurantTypes[data.restaurant?.restaurantType || 'restaurant']}
                    secondary="Rodzaj lokalu gastronomicznego"
                />
            </ListItem>
        )}
        {data.restaurantType === 'chain' && (
            <>
                <ListItem>
                    <ListItemText
                        primary={
                            restaurantTypes[data.restaurant?.restaurantType || 'restaurant']
                        }
                        secondary="Rodzaj lokalu gastronomicznego"
                    />
                    <ListItemSecondaryAction>
                        <Tooltip title="Zobacz lokale">
                            <IconButton onClick={onChainListOpen}>
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={data.maxPlaces || '-'}
                        secondary="Limit lokali gastronomicznych"
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={(data.monthlyPrice / 100).toFixed(2) + ' PLN' || '-'}
                        secondary="Cena miesięczna"
                    />
                    <ListItemSecondaryAction>
                        <Tooltip title="Zmień niestandardową ofertę">
                            <IconButton onClick={onCustomPriceOpen}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={(data.activationPrice / 100).toFixed(2) + ' PLN' || '-'}
                        secondary="Opłata aktywacyjna"
                    />
                </ListItem>
            </>
        )}
    </List>
);
