import React, { useState } from 'react';
import useSWR from 'swr';
import DashboardPageContainer from '../layouts/partials/DashboardPageContainer';
import { Typography, Grid, Box, makeStyles, Chip } from '@material-ui/core';
import StatCards from '../components/StatCards';
import Skeleton from '@material-ui/lab/Skeleton';
import ScansChart from '../components/ScansChart';
import BillingDataCard from '../components/BillingDataCard';
import { useParams } from 'react-router-dom';
import AccountDataCard from '../components/AccountDataCard';
import DashboardError from '../components/DashboardError';
import PaymentsTable from '../components/PaymentsTable';
import MenuModal from '../components/admin/MenuModal';
import ValidUntilModal from '../components/admin/ValidUntilModal';
import UserStatusModal from '../components/admin/UserStatusModal';
import CustomPriceModal from '../components/admin/CustomPriceModal';
import ChainListModal from '../components/admin/ChainListModal';

const useStyles = makeStyles(theme => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
}));

const UserDetailsView = () => {
    const classes = useStyles();
    const params = useParams();
    const { data, error, mutate } = useSWR('admin/user/' + params.id);
    const { data: transactionsData, error: transactionsError } = useSWR(
        'admin/user/' + params.id + '/transactions'
    );

    const [statusOpen, setStatusOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [dateOpen, setDateOpen] = useState(false);
    const [customPriceOpen, setCustomPriceOpen] = useState(false);
    const [chainListOpen, setChainListOpen] = useState(false);

    if ((!data && !error) || (!transactionsData && !transactionsData))
        return (
            <DashboardPageContainer>
                <Typography variant="h1">
                    <Skeleton />
                </Typography>
                <Grid className={classes.gridFix} container spacing={3}>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <Skeleton height={200} />
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <Skeleton height={200} />
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <Skeleton height={200} />
                    </Grid>
                </Grid>
                <Grid className={classes.gridFix} container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <Skeleton height={300} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Skeleton height={300} />
                    </Grid>
                </Grid>
            </DashboardPageContainer>
        );
    if (transactionsError || error) {
        return <DashboardError error={error ? error : transactionsError} />;
    }
    return (
        <DashboardPageContainer>
            <Box ml={2} mb={2} mt={2}>
                <Typography variant="h1">
                    Szczegóły użytkownika: {data.firstName + ' ' + data.lastName}{' '}
                    {data.roles.includes('admin') && (
                        <Chip color="primary" label="Administrator" size="small" />
                    )}
                </Typography>
            </Box>
            <Grid className={classes.gridFix} container spacing={3}>
                <StatCards
                    adminView={true}
                    data={data}
                    onDateClick={() => {
                        setDateOpen(true);
                    }}
                    onMenuClick={() => {
                        setMenuOpen(true);
                    }}
                ></StatCards>
            </Grid>
            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                    <ScansChart isAdmin={true} stats={data.restaurant.stats}></ScansChart>
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                    <AccountDataCard
                        isAdmin={true}
                        data={data}
                        onClicked={() => {
                            setStatusOpen(true);
                        }}
                    />
                </Grid>
                <Grid item xl={8} lg={8} md={8} xs={12}>
                    <PaymentsTable adminView={true} data={transactionsData}></PaymentsTable>
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                    <BillingDataCard
                        hideButton={true}
                        data={data}
                        onCustomPriceOpen={() => {
                            setCustomPriceOpen(true);
                        }}
                        onChainListOpen={() => {
                            setChainListOpen(true);
                        }}
                    ></BillingDataCard>
                </Grid>
            </Grid>
            <MenuModal
                isOpen={menuOpen}
                close={() => {
                    setMenuOpen(false);
                }}
                menu={data.restaurant.menu}
            />
            <ValidUntilModal
                isOpen={dateOpen}
                close={() => {
                    setDateOpen(false);
                }}
                date={data.restaurant.validUntil}
                restaurantId={data.restaurant._id}
                onSave={value => {
                    mutate(user => ({
                        ...user,
                        restaurant: { ...user.restaurant, validUntil: value },
                    }));
                }}
            />
            <UserStatusModal
                userId={params.id}
                initialValue={data.status}
                close={() => {
                    setStatusOpen(false);
                }}
                isOpen={statusOpen}
                onSave={status => {
                    mutate(user => ({ ...user, status }), true);
                }}
            />
            <CustomPriceModal
                isOpen={customPriceOpen}
                close={() => {
                    setCustomPriceOpen(false);
                    mutate();
                }}
                defaultData={{
                    monthlyPrice: data.monthlyPrice,
                    activationPrice: data.activationPrice,
                    maxPlaces: data.maxPlaces,
                }}
                id={params.id}
            />
            <ChainListModal
                isOpen={chainListOpen}
                close={() => {
                    setChainListOpen(false);
                }}
                id={params.id}
            />
        </DashboardPageContainer>
    );
};

export default UserDetailsView;
