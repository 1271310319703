import React from 'react';
import useSWR from 'swr';
import DashboardPageContainer from '../layouts/partials/DashboardPageContainer';
import { Typography, Grid, Box, makeStyles } from '@material-ui/core';
import StatCards from '../components/admin/StatCards';
import Skeleton from '@material-ui/lab/Skeleton';
import ScansChart from '../components/ScansChart';
import DashboardError from '../components/DashboardError';

const useStyles = makeStyles(theme => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
}));

const DashboardView = () => {
    const classes = useStyles();
    const { data, error } = useSWR('admin/dashboard');
    if (!data && !error)
        return (
            <DashboardPageContainer>
                <Typography variant="h1">
                    <Skeleton />
                </Typography>
                <Grid className={classes.gridFix} container spacing={3}>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <Skeleton height={200} />
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <Skeleton height={200} />
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <Skeleton height={200} />
                    </Grid>
                </Grid>
                <Grid className={classes.gridFix} container spacing={3}>
                    <Grid item xs={12}>
                        <Skeleton height={440} />
                    </Grid>
                </Grid>
            </DashboardPageContainer>
        );
    if (!!error) {
        return <DashboardError error={error} />;
    }

    return (
        <DashboardPageContainer>
            <Box ml={2} mb={2} mt={2}>
                <Typography variant="h1">Pulpit Administratora</Typography>
            </Box>
            <Grid className={classes.gridFix} container spacing={3}>
                <StatCards data={data}></StatCards>
            </Grid>

            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xl={12} lg={12} xs={12}>
                    <ScansChart
                        title="Liczba zeskanowanch kodów QR (wszystkie restauracje)"
                        stats={data.stats}
                    ></ScansChart>
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={12}></Grid>
                <Grid item xl={6} lg={6} md={6} xs={12}></Grid>
            </Grid>
        </DashboardPageContainer>
    );
};

export default DashboardView;
