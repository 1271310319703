import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    makeStyles,
    TablePagination,
    Tooltip,
    IconButton,
    Typography,
    Button,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { AccountStatusChip } from './AccountStatusChip';
import { Add, FilterList, GetApp, People, Visibility } from '@material-ui/icons';
import { RestaurantStatusChip } from './RestaurantStatusChip';
import UserListFilterModal from './UserListFilterModal';
import AddUserModal from './AddUserModal';

const useStyles = makeStyles(theme => ({
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
    downloadButton: {
        marginLeft: '12px',
    },
    cardButton: {
        margin: '10px 8px 0 0 ',
        [theme.breakpoints.down('xs')]: {
            marginTop: '30px',
        },
    },
    cardHeader: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}));

const UsersTable = ({
    data,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalDocs,
    loading,
    onFiltersChanged,
    filtersApplied,
    downloadCsv,
    ...rest
}) => {
    const classes = useStyles();
    const [filterOpen, setFilterOpen] = useState(false);
    const [newOpen, setNewOpen] = useState(false);
    return (
        <>
            <Card {...rest}>
                <CardHeader
                    className={classes.cardHeader}
                    title={
                        <>
                            <People color="primary" className={classes.cardTitleIcon} />
                            Zarządzanie użytkownikami systemu
                        </>
                    }
                    action={
                        <>
                            <Button
                                endIcon={<GetApp />}
                                variant="text"
                                size="small"
                                disabled={false}
                                className={classes.cardButton}
                                download
                                href={process.env.REACT_APP_API_ADDR + downloadCsv}
                            >
                                CSV
                            </Button>
                            <Button
                                endIcon={<FilterList />}
                                variant={filtersApplied ? 'contained' : 'outlined'}
                                size="small"
                                color="primary"
                                disabled={false}
                                className={classes.cardButton}
                                onClick={() => {
                                    setFilterOpen(true);
                                }}
                            >
                                Filtrowanie użytkowników
                            </Button>
                            <Button
                                endIcon={<Add />}
                                variant="contained"
                                size="small"
                                color="primary"
                                disabled={false}
                                className={classes.cardButton}
                                onClick={() => {
                                    setNewOpen(true);
                                }}
                            >
                                Dodaj sieć
                            </Button>
                        </>
                    }
                />
                <CardContent>
                    <Box position="relative">
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Imię</TableCell>
                                        <TableCell>Nazwisko</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell align="center">Status konta</TableCell>
                                        <TableCell align="center">
                                            Status restauracji
                                        </TableCell>
                                        <TableCell align="right">Szczegóły</TableCell>
                                    </TableRow>
                                </TableHead>
                                {loading ? (
                                    <TableCell colSpan={6}>
                                        <Skeleton height={59} />
                                        <Skeleton height={59} />
                                        <Skeleton height={59} />
                                    </TableCell>
                                ) : (
                                    <TableBody>
                                        {data.map(row => (
                                            <TableRow key={row._id}>
                                                <TableCell component="th" scope="row">
                                                    {row.firstName}
                                                </TableCell>
                                                <TableCell>{row.lastName}</TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell align="center">
                                                    <AccountStatusChip
                                                        status={row.status}
                                                        kyc={row.kycStatus}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row?.restaurant?.validUntil ? (
                                                        <RestaurantStatusChip
                                                            date={row.restaurant.validUntil}
                                                        />
                                                    ) : (
                                                        '-'
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Zobacz szczegóły">
                                                        <IconButton
                                                            className={classes.actionButton}
                                                            size="small"
                                                            aria-label="down"
                                                            component={Link}
                                                            to={'/users/' + row._id}
                                                        >
                                                            <Visibility />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {(!data || data.length === 0) && (
                                            <TableRow>
                                                <TableCell colSpan={6}>
                                                    <Typography variant="body2" align="center">
                                                        Nie znaleziono użytkowników.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={totalDocs}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={(event, newPage) => {
                                    setPage(newPage);
                                }}
                                onChangeRowsPerPage={event => {
                                    setRowsPerPage(parseInt(event.target.value, 10));
                                    setPage(0);
                                }}
                                labelRowsPerPage="Liczba wierszy na stronę"
                                labelDisplayedRows={({ from, to, count }) =>
                                    `${from}-${to} z ${count}`
                                }
                            />
                        </TableContainer>
                    </Box>
                </CardContent>
            </Card>
            <UserListFilterModal
                onFiltersChanged={newFilters => {
                    onFiltersChanged(newFilters);
                    setFilterOpen(false);
                }}
                isOpen={filterOpen}
            />
            <AddUserModal
                isOpen={newOpen}
                close={() => {
                    setNewOpen(false);
                }}
            />
        </>
    );
};

export default UsersTable;
