import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '1300px',
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
    },
    inner: {
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
}));

const DashboardPageContainer = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.inner}>{children}</div>
        </div>
    );
};

export default DashboardPageContainer;
