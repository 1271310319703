import React from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    makeStyles,
    CardActions,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    avatar: {
        background: theme.palette.background.gradient,
        height: 56,
        width: 56,
    },
    monthlyResultIcon: {
        color: theme.palette.primary.main,
    },
    monthlyResultValue: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
}));

const StatCard = ({ title, value, icon, info, actionButton, warning }) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container justify="space-between" spacing={3}>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h6">
                            {title}
                        </Typography>
                        <Typography color={warning ? 'error' : 'textPrimary'} variant="h3">
                            {value}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.avatar}>{icon}</Avatar>
                    </Grid>
                </Grid>
                <Box mt={2} display="flex" alignItems="center">
                    {info}
                </Box>
            </CardContent>
            <CardActions>{actionButton}</CardActions>
        </Card>
    );
};

StatCard.propTypes = {
    className: PropTypes.string,
};

export default StatCard;
