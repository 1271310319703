export const validateNIP = function(nip) {
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let checksum = 0;
    for (var i = 0; i < 9; i++) {
        var c = nip[i];
        if (c >= '0' && c <= '9') {
            checksum += parseInt(c) * weights[i];
        } else {
            return false;
        }
    }
    if (checksum % 11 !== parseInt(nip[9])) return false;
    return true;
};

export const getNamePL = name => name?.split('|')[0];

export const getNameEN = name => name?.split('|')[1];

export const formatMealsCount = n => {
    if (n === 0) {
        return '0 dań';
    }
    if (n === 1) {
        return '1 danie';
    }
    if (n < 5) {
        return n + ' dania';
    }
    return n + ' dań';
};

export const formatCategoriesCount = n => {
    if (n === 0) {
        return 'kategorii';
    }
    if (n === 1) {
        return 'kategoria';
    }
    if (n < 5) {
        return 'kategorie';
    }
    return 'kategorii';
};

export const authApiPOST = (path, token, data, onSuccess, onError, onNetworkError) => {
    fetch(process.env.REACT_APP_API_ADDR + path, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(async response => {
            if (!response.ok) {
                const data = await response.json();
                const error = data.error;
                onError(error);
            } else {
                const data = await response.json();
                onSuccess(data);
            }
        })
        .catch(error => {
            onNetworkError(error);
        });
};
