import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    LinearProgress,
    makeStyles,
    MenuItem,
    Select,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../UserContext';
import { authApiPOST } from '../../utils';

const useStyles = makeStyles(() => ({
    modal: {
        minWidth: '300px',
    },
}));

const UserStatusModal = ({ userId, initialValue, isOpen, close, onSave }) => {
    const classes = useStyles();
    const [value, setValue] = useState(initialValue);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useContext(UserContext);
    useEffect(() => {
        if (!!isOpen) {
            setValue(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    const onSubmit = () => {
        setLoading(true);
        setError(null);
        authApiPOST(
            'admin/user/' + userId + '/status',
            user.token,
            { status: value },
            res => {
                setLoading(false);
                setError(null);
                onSave(res.status);
                close();
            },
            err => {
                setError(err.error || 'Wystąpił błąd przy zmianie statusu');
                setLoading(false);
            },
            _ => {
                setError('Wystąpił błąd sieciowy');
                setLoading(false);
            }
        );
    };
    return (
        <Dialog maxWidth="md" open={isOpen} onClose={close}>
            <DialogTitle className={classes.modal}>Zmiana statusu konta</DialogTitle>
            <DialogContent>
                <FormControl className={classes.form} fullWidth={true} variant="outlined">
                    <InputLabel>Status konta użytkownika</InputLabel>
                    <Select
                        value={value}
                        onChange={e => {
                            setValue(e.target.value);
                        }}
                        label="Status konta użytkownika"
                    >
                        <MenuItem value={'s'}>Zablokowane</MenuItem>
                        <MenuItem value={'a'}>Aktywne</MenuItem>
                        <MenuItem disabled={true} value={'u'}>
                            Nowe
                        </MenuItem>
                    </Select>
                </FormControl>
                {loading && <LinearProgress color="primary" />}
                {error && <Alert severity="error">{error}</Alert>}
            </DialogContent>

            <DialogActions>
                <Button disabled={loading} onClick={close}>
                    Zamknij
                </Button>
                <Button disabled={loading} color="primary" onClick={onSubmit}>
                    Zatwierdź
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserStatusModal;
