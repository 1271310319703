import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    //useTheme,
    makeStyles,
    TablePagination,
    Tooltip,
    IconButton,
    Typography,
    Button,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { GetApp, ImportExport } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
    downloadButton: {
        marginLeft: '12px',
    },
    cardButton: {
        margin: '10px 12px 0 0 ',
        [theme.breakpoints.down('xs')]: {
            marginTop: '30px',
        },
    },
    cardHeader: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}));

const InvoicesTable = ({ className, data, reportError, reportDownload, ...rest }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    return (
        <Card {...rest}>
            <CardHeader
                className={classes.cardHeader}
                title={
                    <>
                        <ImportExport color="primary" className={classes.cardTitleIcon} />
                        Eksport faktur do Comarch Optima
                    </>
                }
                action={
                    <>
                        {!reportError && (
                            <Button
                                endIcon={<GetApp />}
                                variant="text"
                                size="small"
                                disabled={false}
                                className={classes.cardButton}
                                download
                                href={process.env.REACT_APP_API_ADDR + reportDownload?.url}
                            >
                                Raport CSV
                            </Button>
                        )}
                    </>
                }
            />
            <CardContent>
                <Box position="relative">
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Miesiąc</TableCell>
                                    <TableCell align="center">Liczba dokumentów</TableCell>
                                    <TableCell align="right">Wartość netto</TableCell>
                                    <TableCell align="right">Wartość brutto</TableCell>
                                    <TableCell align="right">Pobierz</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map(row => (
                                        <TableRow key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {row._id}
                                            </TableCell>
                                            <TableCell align="center">{row.count}</TableCell>
                                            <TableCell align="right">
                                                {(row.netSum / 100).toLocaleString('pl-PL', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) + ' PLN'}
                                            </TableCell>
                                            <TableCell align="right">
                                                {(row.totalSum / 100).toLocaleString('pl-PL', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) + ' PLN'}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.downloadURL && (
                                                    <Tooltip title="Pobierz plik wymiany">
                                                        <IconButton
                                                            size="small"
                                                            className={classes.downloadButton}
                                                            aria-label="delete"
                                                            href={
                                                                process.env
                                                                    .REACT_APP_API_ADDR +
                                                                row.downloadURL
                                                            }
                                                        >
                                                            <GetAppIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {(!data || data.length === 0) && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography variant="body2" align="center">
                                                System nie posiada jeszcze wystawionych faktur.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[8, 25, 50]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={(event, newPage) => {
                                setPage(newPage);
                            }}
                            onChangeRowsPerPage={event => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                            labelRowsPerPage="Liczba wierszy na stronę"
                            labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} z ${count}`
                            }
                        />
                    </TableContainer>
                </Box>
            </CardContent>
        </Card>
    );
};

InvoicesTable.propTypes = {
    className: PropTypes.string,
};

export default InvoicesTable;
