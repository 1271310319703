import React from 'react';
import { Box, Typography } from '@material-ui/core';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';

const CenteredError = ({ title, children }) => (
    <VerticallyCenteredSubLayout>
        <Box textAlign="center">
            <img
                alt={title}
                src="/foodinly_logo_404_web.svg"
                style={{ width: '50%', maxWidth: '260px' }}
            />
        </Box>
        <Typography
            align="center"
            color="textPrimary"
            variant="h1"
            style={{ marginTop: '30px' }}
        >
            {title}
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center">
            {children}
        </Typography>
    </VerticallyCenteredSubLayout>
);

export default CenteredError;
