import React from 'react';
import { Button } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DashboardBottomCard from './DashboardBottomCard';
import { BillingDataList } from './BillingDataList';

const BillingDataCard = ({ data, hideButton, onCustomPriceOpen, onChainListOpen }) => {
    return (
        <DashboardBottomCard
            title="Dane rozliczeniowe"
            icon={<AccountBalanceIcon />}
            button={
                !hideButton ? (
                    <Button
                        endIcon={<SettingsIcon />}
                        color="primary"
                        variant="contained"
                        size="small"
                        component={Link}
                        to="/settings"
                    >
                        Ustawienia konta
                    </Button>
                ) : null
            }
        >
            <BillingDataList
                data={data}
                onCustomPriceOpen={onCustomPriceOpen}
                onChainListOpen={onChainListOpen}
            />
        </DashboardBottomCard>
    );
};

export default BillingDataCard;
