import React, { useEffect, useState } from 'react';
import { Grid, Typography, colors, makeStyles, Button } from '@material-ui/core';
import StatCard from '../StatCard';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { People, Restaurant, Settings } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    avatar: {
        backgroundColor: colors.green[600],
        height: 56,
        width: 56,
    },
    monthlyResultValue: {
        color: theme.palette.primary.main,
    },
    gridFix: {
        margin: 0,
        width: '100%',
    },
}));

const StatCards = ({ data }) => {
    const classes = useStyles();

    const [totalScans, setTotalScans] = useState(0);
    const [currentMonthScans, setCurrentMontScans] = useState(0);

    useEffect(() => {
        let total = 0;
        let current = 0;
        if (data?.stats) {
            for (const [key, value] of Object.entries(data?.stats)) {
                total += value;
                if (key === dayjs().format('YYYY-MM')) {
                    current = value;
                }
            }
        }
        setTotalScans(total);
        setCurrentMontScans(current);
    }, [data]);

    return (
        <>
            <Grid item lg={4} sm={6} xl={4} xs={12}>
                <StatCard
                    title="Liczba użytkowników"
                    value={data.users.total.toLocaleString('pl-PL')}
                    info={
                        <Typography variant="body2" color="textSecondary">
                            <span className={classes.monthlyResultValue}>
                                {data.users.active.toLocaleString('pl-PL')}
                            </span>{' '}
                            aktywnych
                        </Typography>
                    }
                    icon={<People />}
                    actionButton={
                        <Button
                            endIcon={<People />}
                            color="primary"
                            variant="contained"
                            size="small"
                            component={Link}
                            to="/users"
                        >
                            Zarządzaj
                        </Button>
                    }
                />
            </Grid>
            <Grid item lg={4} sm={6} xl={4} xs={12}>
                <StatCard
                    title={'Restauracje w systemie'}
                    value={data.restaurants.total.toLocaleString('pl-PL')}
                    info={
                        <>
                            <Typography variant="body2" color="textSecondary">
                                <span className={classes.monthlyResultValue}>
                                    {data.restaurants.active.toLocaleString('pl-PL')}{' '}
                                </span>
                                posiada aktywną usługę
                            </Typography>
                        </>
                    }
                    icon={<Restaurant />}
                    actionButton={
                        <Button
                            endIcon={<ShoppingCartIcon />}
                            color="primary"
                            variant="contained"
                            size="small"
                            component={Link}
                            to="/payments"
                        >
                            Przeglądaj zamówienia
                        </Button>
                    }
                />
            </Grid>
            <Grid item lg={4} sm={6} xl={4} xs={12}>
                <StatCard
                    title={'Zeskanowane kody QR'}
                    value={totalScans.toLocaleString('pl-PL')}
                    info={
                        <>
                            <Typography variant="body2" color="textSecondary">
                                <span className={classes.monthlyResultValue}>
                                    {currentMonthScans.toLocaleString('pl-PL')}{' '}
                                </span>
                                w bieżącym miesiącu
                            </Typography>
                        </>
                    }
                    icon={<CropFreeIcon />}
                    actionButton={
                        <>
                            <Button
                                endIcon={<Settings />}
                                color="primary"
                                variant="contained"
                                size="small"
                                component={Link}
                                to="/settings"
                            >
                                Ustawienia systemu
                            </Button>
                        </>
                    }
                />
            </Grid>
        </>
    );
};

export default StatCards;
