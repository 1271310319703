import React, { useContext } from 'react';
import { UserContext } from '../UserContext';

const SupportView = () => {
    const { user } = useContext(UserContext);
    return (
        <>
            {user && (
                <iframe
                    src={process.env.REACT_APP_SUPPORT_URL.replace(
                        '-email-',
                        user.email
                    )}
                    title="Wsparcie techniczne iqcode"
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        display: 'flex',
                    }}
                ></iframe>
            )}
        </>
    );
};

export default SupportView;
