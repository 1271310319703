import React from 'react';
import { Card, CardContent, CardHeader, makeStyles, CardActions } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        paddingBottom: '6px !important',
        paddingTop: 0,
    },
    cardTitleIcon: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        color: theme.palette.primary.main,
        '& svg': {
            verticalAlign: 'bottom',
            padding: '0 8px',
        },
    },
    cardActions: {
        justifyContent: 'center',
    },
}));

const DashboardBottomCard = ({ children, icon, title, button }) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardHeader
                title={
                    <>
                        <div className={classes.cardTitleIcon}>{icon}</div>
                        {title}
                    </>
                }
            />
            <CardContent className={classes.content}>{children}</CardContent>
            {!!button && <CardActions className={classes.cardActions}>{button}</CardActions>}
        </Card>
    );
};

export default DashboardBottomCard;
