import React, { useContext } from 'react';
import DashboardBottomCard from '../DashboardBottomCard';
import { Storage } from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { UserContext } from '../../UserContext';

const SystemInfoCard = ({ data }) => {
    const { user } = useContext(UserContext);
    return (
        <DashboardBottomCard title="Informacje o systemie" icon={<Storage />}>
            <List>
                <ListItem>
                    <ListItemText
                        primary={data.version}
                        secondary="Wersja aplikacji serwerowej"
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={data.build}
                        secondary="Kompilacja aplikacji serwerowej"
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={process.env.REACT_APP_VERSION}
                        secondary="Wersja panelu administratora"
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={user.sessionId} secondary="Identyfikator sesji" />
                </ListItem>
            </List>
        </DashboardBottomCard>
    );
};

export default SystemInfoCard;
