import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
} from '@material-ui/core';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import plLocale from 'dayjs/locale/pl';
import { UserContext } from '../../UserContext';
import { authApiPOST } from '../../utils';
import Alert from '@material-ui/lab/Alert';

class LocalizedUtils extends DayjsUtils {
    getDatePickerHeaderText(date) {
        return dayjs(date).format('DD-MM-YYYY HH:mm');
    }
}

const ValidUntilModal = ({ date, isOpen, close, restaurantId, onSave }) => {
    const [value, setValue] = useState(date);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useContext(UserContext);
    useEffect(() => {
        if (!!isOpen) {
            setValue(date);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    const onSubmit = () => {
        setLoading(true);
        setError(null);
        authApiPOST(
            'admin/restaurant/' + restaurantId + '/valid',
            user.token,
            { validUntil: value },
            res => {
                setLoading(false);
                setError(null);
                onSave(value);
                close();
            },
            err => {
                setError(err.error || 'Wystąpił błąd przy zmianie statusu');
                setLoading(false);
            },
            _ => {
                setError('Wystąpił błąd sieciowy');
                setLoading(false);
            }
        );
    };
    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>Ważność usługi</DialogTitle>
            <DialogContent>
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                    <DateTimePicker
                        disabled={loading}
                        label="Wprowadź datę"
                        inputVariant="outlined"
                        value={value}
                        onChange={newValue => {
                            setValue(newValue);
                        }}
                        ampm={false}
                        formatDate={date => {
                            dayjs(date).format('DD-MM-YYYY HH:mm');
                        }}
                        format="DD-MM-YYYY HH:mm"
                        invalidDateMessage="Nieprawidłowy format daty"
                    />
                </MuiPickersUtilsProvider>
                {loading && <LinearProgress color="primary" />}
                {error && <Alert severity="error">{error}</Alert>}
            </DialogContent>

            <DialogActions>
                <Button disabled={loading} onClick={close}>
                    Zamknij
                </Button>
                <Button disabled={loading} color="primary" onClick={onSubmit}>
                    Zatwierdź
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ValidUntilModal;
