import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(_ => ({
    container: {
        margin: 'auto',
        padding: '0 12px',
        paddingBottom: '12px',
        width: '100%',
    },
    box: {
        margin: '0 auto',
        height: '100%',
        display: 'flex',
    },
}));

const VerticallyCenteredSubLayout = ({ children, width }) => {
    const classes = useStyles();
    return (
        <Box className={classes.box} maxWidth={width || '460px'}>
            <div className={classes.container}>{children}</div>
        </Box>
    );
};

export default VerticallyCenteredSubLayout;
