import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
    modal: {
        minWidth: '300px',
    },
    textInput: {
        marginBottom: theme.spacing(2),
    },
}));

const initialFilters = {
    firstName: '',
    lastName: '',
    email: '',
    status: '',
};

const UserListFilterModal = ({ isOpen, close, onFiltersChanged }) => {
    const classes = useStyles();
    const [filters, setFilters] = useState(initialFilters);
    return (
        <Dialog maxWidth="sm" open={isOpen} onClose={close}>
            <DialogTitle className={classes.modal}>Filtrowanie kont użytkowników</DialogTitle>
            <DialogContent>
                <TextField
                    onChange={e => {
                        setFilters({ ...filters, firstName: e.target.value });
                    }}
                    variant="outlined"
                    fullWidth={true}
                    value={filters.firstName}
                    label="Imię"
                    className={classes.textInput}
                />
                <TextField
                    onChange={e => {
                        setFilters({ ...filters, lastName: e.target.value });
                    }}
                    variant="outlined"
                    fullWidth={true}
                    value={filters.lastName}
                    label="Nazwisko"
                    className={classes.textInput}
                />
                <TextField
                    onChange={e => {
                        setFilters({ ...filters, email: e.target.value });
                    }}
                    variant="outlined"
                    fullWidth={true}
                    value={filters.email}
                    label="Adres e-mail"
                    className={classes.textInput}
                />
                <FormControl className={classes.form} fullWidth={true} variant="outlined">
                    <InputLabel>Status konta użytkownika</InputLabel>
                    <Select
                        value={filters.status}
                        onChange={e => {
                            setFilters({ ...filters, status: e.target.value });
                        }}
                        label="Status konta użytkownika"
                    >
                        <MenuItem value={''}>Dowolny</MenuItem>
                        <MenuItem value={'s'}>Zablokowane</MenuItem>
                        <MenuItem value={'a'}>Aktywne</MenuItem>
                        <MenuItem value={'u'}>Nowe</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => {
                        setFilters(initialFilters);
                        onFiltersChanged(initialFilters);
                    }}
                >
                    Wyczyść filtry
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        onFiltersChanged(filters);
                    }}
                >
                    Zastosuj
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserListFilterModal;
