import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import dayjs from 'dayjs';
import { IconButton, ListItemSecondaryAction, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

const statuses = {
    a: 'Aktywne',
    u: 'Nowe (oczekuje na aktywację)',
    s: 'Zablokowane',
};

export const AccountDataList = ({ data, isAdmin, handleClick }) => (
    <List>
        <ListItem>
            <ListItemText
                primary={data.firstName + ' ' + data.lastName}
                secondary="Imię i Nazwisko"
            />
        </ListItem>
        <ListItem>
            <ListItemText primary={data.email} secondary="Adres e-mail" />
        </ListItem>
        <ListItem>
            <ListItemText primary={data.phone} secondary="Numer telefonu" />
        </ListItem>
        <ListItem>
            <ListItemText
                primary={data.restaurant.codeId || 'Nie wygenerowano'}
                secondary="Identyfikator restauracji"
            />
        </ListItem>
        {isAdmin && (
            <>
                <ListItem>
                    <ListItemText
                        primary={
                            data?.lastLogin
                                ? dayjs(data.lastLogin).format('DD-MM-YYYY HH:mm')
                                : 'Nigdy'
                        }
                        secondary="Ostatnie logowanie"
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={
                            (statuses[data.status] || '-') +
                            ' (KYC: ' +
                            (data.kycStatus || '-') +
                            (!!data.kycDocumentSent ? '+' : '') +
                            ')'
                        }
                        secondary="Status konta i poziom weryfikacji"
                    />
                    <ListItemSecondaryAction>
                        <Tooltip title="Zmień status konta">
                            <IconButton onClick={handleClick}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
            </>
        )}
    </List>
);
