import { Link } from '@material-ui/core';
import React from 'react';
import CenteredError from './CenteredError';
import { Link as RouterLink } from 'react-router-dom';

const DashboardError = ({ error }) => (
    <CenteredError title={error.msg ? 'Coś poszło nie tak' : 'Błąd połączenia'}>
        {error.msg && (
            <>
                {error.msg}
                <br />
                <Link to={'/'} component={RouterLink}>
                    Przejdź do ekranu głównego
                </Link>
                .
            </>
        )}
        {!error.msg && (
            <>
                Wystąpił błąd połączenia z serwerem. Sprawdź swoje połączenie internetowe i
                spróbuj{' '}
                <Link to={'/'} component={RouterLink}>
                    przejść do ekranu głównego
                </Link>
                .
            </>
        )}
    </CenteredError>
);

export default DashboardError;
