import React, { useState } from 'react';
import useSWR from 'swr';
import DashboardPageContainer from '../layouts/partials/DashboardPageContainer';
import { Typography, Grid, Box, makeStyles } from '@material-ui/core';
import PaymentsTable from '../components/admin/PaymentsTable';
import DashboardError from '../components/DashboardError';
import { initialFilters } from '../components/admin/OrdersListFilterModal';

const useStyles = makeStyles(() => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
}));

const areFiltersApplied = f => {
    for (const [key] of Object.entries(initialFilters)) {
        if (f[key] !== '') {
            return true;
        }
    }
    return false;
};

const OrdersView = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [filters, setFilters] = useState(initialFilters);
    const { data, error } = useSWR(
        `admin/orders?page=${page + 1}&limit=${rowsPerPage}&status=${
            filters.status
        }&totalPriceFrom=${filters.totalPriceFrom}&totalPriceTo=${
            filters.totalPriceTo
        }&invoiceId=${filters.invoiceId}&createdFrom=${filters.createdFrom}&createdTo=${
            filters.createdTo
        }`
    );
    if (!!error) {
        return <DashboardError error={error} />;
    }
    return (
        <DashboardPageContainer>
            <Box ml={2} mb={2} mt={2}>
                <Typography variant="h1">Zamówienia</Typography>
            </Box>

            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xl={12} lg={12} xs={12}>
                    <PaymentsTable
                        loading={!data && !error}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        page={page}
                        setPage={setPage}
                        data={data?.docs || []}
                        totalDocs={data?.totalDocs || 0}
                        totalPages={data?.totalPages || 1}
                        onFiltersChanged={newFilters => {
                            setFilters({ ...initialFilters, ...newFilters });
                            setPage(0);
                        }}
                        filtersApplied={areFiltersApplied(filters)}
                        downloadCsv={`admin/orderscsv?status=${filters.status}&totalPriceFrom=${filters.totalPriceFrom}&totalPriceTo=${filters.totalPriceTo}&invoiceId=${filters.invoiceId}&createdFrom=${filters.createdFrom}&createdTo=${filters.createdTo}&token=${data?.downloadToken}&ttl=${data?.downloadTTL}`}
                    ></PaymentsTable>
                </Grid>
            </Grid>
        </DashboardPageContainer>
    );
};

export default OrdersView;
