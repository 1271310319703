import React from 'react';
import useSWR from 'swr';
import DashboardPageContainer from '../layouts/partials/DashboardPageContainer';
import { Typography, Grid, Box, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import InvoicesTable from '../components/admin/InvoicesTable';
import DashboardError from '../components/DashboardError';

const useStyles = makeStyles(() => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
}));

const InvoicesView = () => {
    const classes = useStyles();
    const { data, error } = useSWR('admin/invoices');
    const { data: reportDownload, error: reportError } = useSWR('admin/generatereportlink');
    if (!data && !error)
        return (
            <DashboardPageContainer>
                <Typography variant="h1">
                    <Skeleton />
                </Typography>
                <Grid className={classes.gridFix} container spacing={3}>
                    <Grid item xs={12}>
                        <Skeleton height={440} />
                    </Grid>
                </Grid>
            </DashboardPageContainer>
        );

    if (!!error) {
        return <DashboardError error={error} />;
    }

    return (
        <DashboardPageContainer>
            <Box ml={2} mb={2} mt={2}>
                <Typography variant="h1">Eksport faktur</Typography>
            </Box>

            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xl={12} lg={12} xs={12}>
                    <InvoicesTable
                        reportDownload={reportDownload}
                        reportError={reportError}
                        data={[...data].reverse()}
                    ></InvoicesTable>
                </Grid>
            </Grid>
        </DashboardPageContainer>
    );
};

export default InvoicesView;
